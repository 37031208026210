
import { defineAsyncComponent, defineComponent } from 'vue'
import router from '@/router'
import { OrderActions } from '@/store/modules/orders/actions'
import { DarkStoresActions } from '@/store/modules/darkstores/actions'
import { AggregateDarkstoreRevenueInterface } from '@/shared/interfaces/reports/aggregate-darkstore-revenue.interface'

export default defineComponent({
  components: {
    BaseIcon: defineAsyncComponent(() => import('@/shared/components/base-icon/BaseIcon.vue')),
    FilterPanel: defineAsyncComponent(() => import('@/shared/components/filter/FilterPanel.vue')),
    PageTitle: defineAsyncComponent(() => import('@/shared/components/PageTitle.vue')),
    DynamicTable: defineAsyncComponent(() => import('@/shared/components/dynamic-table/DynamicTable.vue')),
    Multiselect: defineAsyncComponent(() => import('@vueform/multiselect'))
  },
  data () {
    return {
      darkstoreId: null as any,
      showFilterModal: false,
      tableFields: [
        { id: 'name', name: 'Darkstore', width: '25%', sortable: false, currentSort: false, sort: null },
        { id: 'orderStatusName', name: 'Order Status', width: '25%', sortable: false, currentSort: false, sort: null },
        { id: 'totalCollectedOrderAmount', name: 'Collected Order Amount', width: '25%', sortable: false, currentSort: false, sort: null },
        { id: 'totalOrders', name: 'Total Orders', width: '25%' }
      ],
      filters: [
        { name: this.$t('filters.date'), model: 'orderCreatedAt', type: 'date', calendarType: 'range' },
        {
          name: this.$t('filters.orderStatus'),
          model: 'status',
          dataLoading: true,
          fetchDataOnExpand: true,
          dataRequested: false,
          actionName: 'orderStatus',
          type: 'radio',
          data: []
        }
      ],
      selectedReport: this.reportType,
      exportFields: [
        { name: 'Darkstore', id: 'name' },
        { name: 'Order Status', id: 'orderStatusName' },
        { name: 'Total Orders', id: 'totalOrders' },
        { name: 'Total Collected Order Amount', id: 'totalCollectedOrderAmount' }
      ] as any
    }
  },
  methods: {
    menuEvent (e: any): void {
      console.log(e)
    },
    onExpandPanelClick (eventName: string): void {
      if (eventName === 'orderStatus') {
        this.$store.dispatch(`orders/${OrderActions.FETCH_ORDER_STATUSES}`)
      }
    },
    clickSort (field: any): void {
      this.tableFields = this.tableFields?.map((el: any) => {
        if (el.id === field.id) {
          el.currentSort = true
          if (el.sort === null) {
            el.sort = 'asc'
          } else if (el.sort === 'asc') {
            el.sort = 'desc'
          } else if (el.sort === 'desc') {
            el.sort = null
          }
        } else {
          el.currentSort = false
          el.sort = null
        }
        return el
      })
      router.push({ query: { ...this.$route.query, orderBy: field.sortName, sort: field.sort } })
    }
  },
  watch: {
    darkstoreId (val) {
      let queries = this.$route.query
      queries.pageNumber = '1'
      if (val === null) {
        queries = {
          pageNumber: '1'
        }
        this.showFilterModal = false
      }
      router.push({ query: { ...queries, darkstoreId: val ? val as any : undefined } })
    },
    $route: {
      immediate: true,
      handler (val: any) {
        if (val.query.orderBy) {
          this.tableFields = this.tableFields.map((el: any) => {
            if (el.id === val.query.orderBy) {
              el.currentSort = true
              el.sort = val.query.sort
            }
            return el
          })
        }
      }
    },
    selectedReport (val) {
      if (val) {
        router.push({ query: { reportName: val.name } })
      }
    },
    statuses (val: any) {
      if (!val.length) return
      const filterItems = val.map((el: any) => {
        return {
          id: el.id,
          label: el.value
        }
      })
      const target = this.filters[1]
      target.dataRequested = true
      target.data = filterItems
      target.dataLoading = false
    }
  },
  props: {
    reportType: {
      type: String
    }
  },
  computed: {
    reports (): AggregateDarkstoreRevenueInterface[] {
      return this.$store.getters['reports/getAggregateDarkstoreRevenueReports']
    },
    exportReports () {
      return this.$store.getters['reports/getAggregateDarkstoreRevenueExportReports']
    },
    pagination () {
      return this.$store.getters['reports/getAggregateDarkstoreRevenuePagination']
    },
    loading (): boolean {
      return this.$store.getters['reports/getLoading']
    },
    statuses (): any {
      return this.$store.getters['orders/getOrderStatuses']
    },
    darkstores (): any[] {
      return this.$store.getters['darkstores/getDarkStoresAsDictionary']
    }
  },
  mounted () {
    if (!this.darkstores.length) {
      this.$store.dispatch(`darkstores/${DarkStoresActions.FETCH_DARKSTORES}`, { pageNumber: 1, pageSize: 100 })
    }
  }
})
